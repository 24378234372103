.step__content {
  width: 281px;
  position: relative;

  &__line {
    border-top: #E0E0E0 5px dotted;
    height: 2px;
    border-bottom: none;
    border-left: none;
    position: absolute;
    top: 20px;
    left: -190px;
    width: 272px;

  }

  &__noline {
display: none;
  }

  &__number {
    position: relative;
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 26px;

    &__circle {
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 50%;
      padding: 8px;
      background-color: #e0e0e0;
    }

    &__number {
      position: absolute;
      font-size: 2rem;
      font-weight: 800;
      color: #000;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    }

    
  }
  &__title {
    font-size: 1rem;
    font-weight: 800;
    padding-bottom: 18px;
    text-align: left;
  }

  &__description {
    text-align: left;
    font-size: 1rem;


    &__mail {
      background-color: green($color: #000000);
    }
  }
}

@media (max-width: 1110px) {
    .step__content__line {
        display: none;
    }
}

