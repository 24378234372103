.popup {
  color: #f7b01c;
}

::placeholder {
  color: #989898;
}
.description {
  padding-top: 20px;

  &__title {
    color: #989898;
  }

  &__data {
    width: 100%;
    height: 88px;
    border: 1px solid black;
    resize: none;
    margin-top: 5px;
  }

  textarea:focus {
    outline: none;
  }
}

.guaranteeData {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 56px;

  &__proof {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    gap: 20px;

    &--file {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    input[type='file'] {
      position: fixed;
      right: 100%;
      bottom: 100%;
    }

    label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 6px 12px;
      cursor: pointer;
      font-size: 1rem;
      color: #989898;
      padding-bottom: 10px;

      & img {
        padding-top: 10px;
      }
    }

    span {
      font-size: 0.875rem;
      color: #373737;
      margin: 0 auto;
      max-width: 140px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.description__data {
  padding: 5px;
}

.maxPrice {
  padding-top: 56px;

  &__description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 9px;

    & span {
      color: #989898;
    }
  }
  &__select {
   border-bottom: 1px solid black;
   margin-top: 19px;

   &:focus {
    outline: none;
   }
  }
}

input[type='number'] {
  -moz-appearance:textfield;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

a:link, a:visited {
  color: #f7b01c;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 768px) {
 .line {
  display: flex;
  flex-direction: column;
  padding: 0
 }

 .guaranteeData {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  gap: 20px;

}

.guaranteeData__proof label {
  padding: 6px 36px;
}
}