* {
  font-family: "Poppins", sans-serif;
  line-height: 1.563rem;
  box-sizing: border-box;
}



.main {
  max-width: 1920px;
  // padding: 0 315px 0 250px;
  display: flex;
  flex: row;
  justify-content: space-evenly;
  margin: 0 auto;
}

.container {
  width: 850px;
  margin: 0 auto;
  padding-top: 100px;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.25rem;
  border-bottom: solid black 2px;
  line-height: 30px;

  &--description {
    padding: 25px 0;
    color: #989898;
  }

  img {
    padding: 0 14px 14px 0;
  }
}

h2 {
  font: normal normal 600 20px/30px Poppins;
}

.data {
  padding-top: 30px;
}

.line {
  padding: 18px 0;
  display: flex;
  justify-content: space-between;
}

input {
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid black;
  min-width: 0;
}
:-ms-input-placeholder {
  color: #989898;
}

input:focus {
  outline: none;
}

.long {
  width: 390px;
  padding-bottom: 5px;
}

.short {
  width: 160px;
  padding-bottom: 5px;
}

.verylong {
  width: 615px;
  padding-bottom: 5px;
}

.big {
  width: 100%;
}

.alert {
  color: red;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}


@media (max-width: 1366px) and (min-width: 1024px) {
  .main {
    max-width: 1024px;
    display: block;
    margin: 0 auto;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .main {
    max-width: 688px;
    margin: 0 auto;
    display: block;
  }
  .container {
    max-width: 688px;
    margin: 0;
    padding-top: 100px;
    display: block;
  }
  .long {
    width: 309px;
    padding-bottom: 5px;
  }

  .short {
    width: 120px;
    padding-bottom: 5px;
  }

  .verylong {
    width: 498px;
    padding-bottom: 5px;
  }
}

@media (max-width: 768px) {
  .long {
    width: 100%;
    padding-bottom: 5px;
  }

  .short {
    width: 100%;
    padding-bottom: 5px;
  }

  .verylong {
    width: 100%;
    padding-bottom: 5px;
  }

  .container {
    margin: 0 auto;
    padding: 70px 30px 20px;
    width: 100%;
    // padding-top: 100px;
    display: block;
  }
}

