.confirmation {
  display: flex;
  justify-content: center;
  margin-top: 100px;

  .confirmationButton {
    margin: 0 0 200px;
    padding: 15px 39px;
    color: #373737;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color 150ms ease-in-out;
  }

  &__buttonLocked {
    background-color: #f3f3f3;
  }
  &__buttonUnlocked {
    background-color: #fff;
    border: 2px solid #f7b01c;

    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 100%;
      background-color: #f7b01c;
      z-index: -1;
      transition: width 150ms ease-in-out;
    }
    &:hover {
      color: #fff;
      &:after {
        width: 110%;
      }
    }
  }
}

 