.Clipboard__input {
    display: none;
}

.Clipboard__button {
    display: flex;
    width: 250px;
    height: 60px;
    background-color: #f7b01c;
    cursor: pointer;
    padding: 15px 39px;
    color: #fff;
    position: relative;
    justify-content: center;
   align-items: center;
   margin: 88px;
   overflow: hidden;
   transition: color 150ms ease-in-out;
   font-size: 1.25rem;
   z-index: 201;
   border: 2px solid #f7b01c;


   &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background-color: #fff;
    
    z-index: -1;
    transition: width 150ms ease-in-out;
  }
   &:hover {
    color: #373737;
    &:after {
      width: 110%;
    }
  }
}

@media (max-width: 1110px) and (min-width: 768px) {
  .Clipboard__button {
  margin: 2rem;
  }

}