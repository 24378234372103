.complaintType__selection {
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  &--option {
    width: 249px;
    height: 143px;
    box-shadow: 0px 3px 10px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    .shield {
      width: 35px;
      height: 44px;
      margin-bottom: 16px;
      background-color: black;
      mask: url(../../../../../public/img/shield_icon.svg) no-repeat center;
    }
    .wallet {
      width: 46px;
      height: 41px;
      margin-bottom: 16px;
      background-color: black;
      mask: url(../../../../../public/img/wallet_icon.svg) no-repeat center;
    }

    &:hover {
      transform: scale(1.1);
    }

    &:hover .wallet,
    &:hover .shield {
      background-color: #f7b01c;
    }
  }
  .clickedBorder {
    border: 1px solid #f7b01c;
  }

  .clicked {
    width: 249px;
    height: 143px;
    box-shadow: 0px 3px 10px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #f7b01c;

    .shield {
      width: 35px;
      height: 44px;
      margin-bottom: 16px;
      background-color: #f7b01c;

      mask: url(../../../../../public/img/shield_icon.svg) no-repeat center;
    }
    .wallet {
      width: 46px;
      height: 41px;
      margin-bottom: 16px;
      background-color: #f7b01c;
      mask: url(../../../../../public/img/wallet_icon.svg) no-repeat center;
    }

    &:hover {
      transform: none;
    }

    &:hover .wallet,
    &:hover .shield {
      background-color: #f7b01c;
    }
  }
}

.complaintType__checkbox {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  align-items: flex-start;
  gap: 13px;
  padding-top: 59px;

  [type="checkbox"]:not(:checked) + label,
  [type="checkbox"]:checked + label {
    cursor: pointer;
  }

  .control {
    display: block;
    position: relative;
    padding-left: 33px;
    margin-bottom: 0px;
    padding-top: 5px;
    cursor: pointer;
    font-size: 1rem;
    color: #91959c;
  }
  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .control_indicator {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #ffffff;
    border: 2px solid #91959c;
    border-radius: 2px;
  }
  .control:hover input ~ .control_indicator,
  .control input:focus ~ .control_indicator {
    background: #ffffff;
  }

  .control input:checked ~ .control_indicator {
    background: #ffffff;
    border: 2px solid #f7b01c;
  }
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  left: 6px;
  top: -1px;
  width: 5px;
  height: 11px;
  border: solid #f7b01c;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

input[type="radio"] {
  position: fixed;
  right: 100%;
  bottom: 100%;
}

label {
  cursor: pointer;
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  -webkit-tap-highlight-color: transparent;
}

@media (max-width: 768px) {
  .complaintType__selection {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    justify-content: center;
    align-items: center;
    gap: 38px;
  }
}
