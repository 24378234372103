.header {
  width: 100%;
  max-height: 370px;
  background-color: black;


  & img {
    object-fit: contain;
    max-width: 100%;
    max-height: 370px;
    margin: 0 auto;
  }
}


// @media (max-width: 1024px) and (min-width: 768px) {
//  .header img  {
//   max-width: 688px
//  }
// }

// @media (max-width: 1920px) {
//   .header {
//     width: 100%;
//     max-height: 370px;
//     background-color: black;
  
  
//     & img {
//       object-fit: contain;
//       margin: 0 auto;
//     }
//   }
//   }

// @media (max-width: 1820px) {
//   .header {
//     height: auto;
//     & img {
//       width: auto;
//     }
//   }
// }
