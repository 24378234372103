.NavBar {
  min-width: 250px;



  &__list {
    padding-top: 150px;
    position: sticky;
    top: 100px;

    &__element {
      padding: 30px;
      font-size: 1rem;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      &--number {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        padding: 8px;
        background-color: #fff;
        border: 3px solid #f7b01c;
        color: #000;
        text-align: center;
        font-size: 1.25rem;
        margin-right: 31px;
        position: absolute;
        z-index: -3;

        &__active {
          border-radius: 50%;
          width: 45px;
          height: 45px;
          padding: 8px;
          background-color: #fff;
          border: 3px solid #f7b01c;
          color: #000;
          text-align: center;
          font-size: 1.25rem;
          margin-right: 31px;
          animation: circle 0.5s ease-in forwards;
          position: absolute;
          z-index: -3;
        }
      }

      p {
        margin-left: 60px;
        z-index: 3;
        color: var(--black);
        font-weight: 700;
        font-size: 1rem;
      }
    }
  }
}

@keyframes circle {
  0% {
    background-color: #fff;
    width: 45px;
    border-radius: 50%;
  }
  3% {
    background-color: #f7b01c;
    width: 45px;
    border-radius: 50%;
  }
  20% {
    background-color: #f7b01c;
    border-radius: 30px;
  }
  30% {
    background-color: #f7b01c;
    border-radius: 35px;
  }
  40% {
    background-color: #f7b01c;
    border-radius: 40px;
  }
  100% {
    background-color: #f7b01c;
    width: 230px;
    border-radius: 50px;
  }
}

.one::after {
  content: '1';
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  left: 9px;
}

.two::after {
  content: '2';
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  left: 9px;
}

.three::after {
  content: '3';
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  left: 9px;
}

.four::after {
  content: '4';
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  left: 9px;
}

.five::after {
  content: '5';
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  left: 9px;
}


@media (max-width: 1366px) {
  .NavBar {display: none;}
}