.ModalDHLError {
  width: 80.625rem;
  height: 52.5rem;
  padding: 37px 37px 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 110;
  background-color: #fff;
  line-height: 40px;
  overflow-y: auto;
  overflow-x: hidden;
//   gap: 0px;

  .closeBtnContainer {
    width: 100%;
    padding-left: 1160px;
  }
  .ErrorTitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
  .message {
    display: flex;
    flex-direction: row;
    gap: 20px;
    background-color: #f0f0f0;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 400;
    max-width: 930px;
    padding: 21px 42px;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .info {
    max-width: 830px;
    color: #7c7c7c;
    font-size: 16px;
  }

  .button {
    background-color: #f7b01c;
    width: 200px;
    border-radius: 22px;
    cursor: pointer;
    &:hover {
      box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.102);
    }
  }
}

@media (max-width: 1366px) {
  .ModalDHLError {
    width: 768px;
    height: 100%;
    overflow-x: auto;

    .closeBtnContainer {
        width: 100%;
        padding-left: 660px;
      }
  }
}

@media (max-width:  768px) {
  .ModalDHLError {
    z-index: 102;
    width: 100%;
    max-height: 100%;
    padding: 20px 40px;
    overflow-x: auto;
    justify-content: flex-start;
    gap: 70px;

    .closeBtnContainer {
        width: 100%;
        padding-left: 95%;
      }
  }
}
