/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
  padding: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
  font-size: 16px;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
  border: none;
  background-color: #fff;
}


.label {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  -webkit-tap-highlight-color: transparent;
}

.error {
  color: red;
  cursor:auto;
  font-size: 0.875rem;
  padding-bottom: 10px;
}

/* @media (max-width: 1366px) and (min-width: 768px) {
  html {
      font-size: 14px;
  }
}

@media (max-width: 768px) {
  html {
      font-size: 14px;
  }
} */