.UserDetails__form {
  // width: 100%;
  // height: 363px;

  &--title {
    display: flex;
    gap: 36px;

    &--option {
      color: #373737;
      background-color: #f2f2f2;
      padding: 4px 15px 4px 35px;
      border-radius: 20px;
      cursor: pointer;
      position: relative;
      -webkit-tap-highlight-color: transparent;

      &::before {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        content: "";
        background-color: #fff;
        position: absolute;
        top: 9px;
        left: 9px;
      }
    }

    &--clicked {
      font-weight: 600;
      color: #373737;
      background-color: #f2f2f2;
      padding: 4px 15px 4px 35px;
      border-radius: 20px;
      cursor: pointer;
      position: relative;

      &::before {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        content: url("../../../../../public/img/checkbox_round.svg");
        position: absolute;
        top: 5px;
        left: 5px;
      }
    }
  }

  &__data {
    padding-top: 30px;

    &--line {
      padding: 18px 0;
      display: flex;
      justify-content: space-between;
    }

    input {
      margin: 10px 0;
      border: none;
      border-bottom: 1px solid black;
      min-width: 0;
      background-color: #fff;
      cursor: pointer;
    }

    input:focus {
      outline: none;
    }

    .long {
      width: 390px;
    }

    .short {
      width: 160px;
    }

    .verylong {
      width: 615px;
    }
  }
}

@media (min-width: 768px) {
  .houseNumber {
    display: flex;
    flex-direction: row;
    gap: 70px;
  }
}

@media (max-width: 768px)  {
  .UserDetails__form__data--line {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .UserDetails__form--title {
    gap: 30px;
  }

  input {
    padding-top: 10px
  }
  .longZipCode {
    width: 100%;
    padding-bottom: 5px;
  }
}


