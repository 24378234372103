.PurchaseDate {
  position: relative;

  &--icon {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 2;

    &:hover {
      color: #373737;
    }
  }

  &__DatePicker {
    padding-left: 50px;
    margin-right: 20px;
    cursor: pointer;
  }

  &__wrapper {
    background-color: plum;
  }
}

@media (max-width: 768px) {
  .PurchaseDate {
    width: 100%;
    border-bottom: 1px solid #000;
    &--icon {
      top: 15px;
    }
    &__DatePicker {
      border: none;
    }
  }
}
