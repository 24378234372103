.PackageDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 52px;

  &__option {
    width: 338px;
    height: 73px;
    box-shadow: #00000029 0 2px 6px;
    margin: 63px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    -webkit-tap-highlight-color: transparent;

    cursor: pointer;

    &--text {
      width: 150px;
      margin-left: 36px;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}

.clickedDeliveryOption {
  width: 338px;
  height: 73px;
  box-shadow: #00000029 0 2px 6px;
  margin: 63px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #f7b01c;
  -webkit-tap-highlight-color: transparent;
}

.block {
  background-color: #f3f3f3;
  width: 338px;
  height: 73px;
  box-shadow: #00000029 0 2px 6px;
  margin: 63px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  &--text {
    width: 150px;
    margin-left: 36px;
  }
  &:hover {
    transform: scale(1.1);
  }
}

.packageSize {
  margin-top: 63px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 25px;

  &__option {
    background-color: #fff;
    width: 138px;
    height: 172px;
    box-shadow: #00000029 0 3px 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    &--text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.ChoosingCompany {
  width: 728px;
}

.CompanyList {
  &__options {
    margin: 20px 62px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 52px;

    &__option {
      width: 208px;
      height: 113px;
      box-shadow: #00000029 0 2px 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in-out;
      margin-bottom: 80px;

      & img {
        -webkit-backface-visibility: hidden;
        -ms-transform: translateZ(0); /* IE 9 */
        -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
        transform: scale(0.9, 0.9);
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.clickedOption {
  width: 208px;
  height: 113px;
  box-shadow: #00000029 0 2px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  margin-bottom: 80px;
  border: 1px solid #f7b01c;

  & img {
    width: 100px;
  }
}

.massage {
  padding-bottom: 63px;
}

.disabled {
  background-color: #f3f3f3;
  color: #373737;
  transform: none;
  width: 138px;
  height: 172px;
  box-shadow: #00000029 0 3px 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  pointer-events: none;
  cursor: none;
}

.disabled:hover {
  transform: none;
}

.activePackage {
  border: 1px solid #f7b01c;
  background-color: #fff;
  width: 138px;
  height: 172px;
  box-shadow: #00000029 0 3px 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

label {
  padding-top: 0;
  -webkit-tap-highlight-color: transparent;
}

.gray {
  width: 338px;
  height: 73px;
  box-shadow: #00000029 0 2px 6px;
  margin: 63px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;
  background-color: #f3f3f3;
  cursor: pointer;

  &--text {
    width: 150px;
    margin-left: 36px;
  }
  &:hover {
    transform: scale(1.1);
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .CompanyList__options {
    margin: 0 auto;
    gap: 32px;
  }
}

@media (max-width: 768px) {
  .PackageDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0;
    margin: 40px auto 63px;

    &__option {
      margin: 16px 0;
      max-width: 296px;
    }
  }

  .packageSize {
    gap: 18px;
    justify-content: center;
  }

  .clickedDeliveryOption {
    width: 296px;
    margin: 38px 0;
  }

  .gray {
    width: 296px;
    margin: 38px 0;
  }

  .ChoosingCompany {
    width: 330px;
  }
  .CompanyList {
    margin: 0 auto;
    &__options {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0;

      &__option {
        width: 296px;
        margin: 27px auto;
        height: 75px;
      }
    }
    .clickedOption {
      width: 296px;
      height: 75px;
      margin: 27px auto;

    }
  }
}
