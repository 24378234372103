.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 50px 96px;
  z-index: 102;

  &__title {
    &__closeButton {
      width: 15px;
      height: 15px;
      padding-left: 1160px;
      cursor: pointer;
    }

    &__text {
      text-align: center;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  &__summary {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 250px 250px;
    column-gap: 167px;
    row-gap: 40px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 80%;
    width: 100%;

    &__userDetails {
      width: 1098px;
      padding-top: 33px;
      grid-column: span 2;

      &__info {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 30px;
        column-gap: 15px;
        padding-top: 15px;
        color: #989898;
      }
    }
    &__toolsSummary,
    &__deliverySummary {
      &__container {
        padding-top: 13px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 60px 60px;
        column-gap: 15px;
        padding-top: 15px;
        color: #989898;

        &__data {
          color: #989898;
          min-height: 55px;
        }
      }
    }

    .summaryTitle {
      border-bottom: solid black 2px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      padding-bottom: 13px;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(7px);

  }
}

.fromUser {
  color: #373737;
  overflow-wrap: break-word;
  padding-top: 5px;
}

.confirmationButtonBox {
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  margin: 20px;

  &__button {
    border: 2px solid #f7b01c;
    padding: 16px 61px;
    color: #373737;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color 150ms ease-in-out;

    &:hover,
    &:focus {
      color: #fff;
      outline: 0;
    }

    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 100%;
      background: #f7b01c;
      z-index: -1;
      transition: width 150ms ease-in-out;
    }

    &:hover {
      color: #fff;
      &:after {
        width: 110%;
      }
    }
  }
}



@media (max-width: 1366px) and (min-width: 768px) {
  .Modal {
    width: 768px;
    max-height: 100vh;
    padding: 40px 40px;
    overflow-x: auto;

    
    &__summary {
      display: flex;
      flex-direction: column;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 300px;
      column-gap: 10px;
      row-gap: 40px;
      height: 70%;

      &__userDetails {
        width: 100%;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
        padding-top: 33px;

        &__info {
          display: flex;
          flex-direction: column;
          color: #989898;
          gap: 15px;
        }
      }

      &__toolsSummary {
        width: 292px;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
        padding-top: 33px;
        justify-self: end;

        &__container {
          display: flex;
          flex-direction: column;
          gap: 15px;
          padding-top: 15px;
        }
      }

      &__deliverySummary {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
        padding-top: 33px;

        &__container {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-rows: 1fr;
          padding-top: 15px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .Modal {
    position: fixed;
    z-index: 102;

    width: 100%;
    max-height: 100%;
    padding: 20px 40px;
    overflow-x: auto;

    
    &__summary {
      display: flex;
      flex-direction: column;
      height: 70%;

      &__userDetails {
        width: 100%;

        &__info {
          display: flex;
          flex-direction: column;
          color: #989898;
          gap: 15px;
        }
      }
      &__toolsSummary {
        width: 100%;

        &__container {
          display: flex;
          flex-direction: column;
          gap: 15px;
          padding-top: 15px;
        }
      }

      &__deliverySummary {
        width: 100%;
        padding-bottom: 20px;

        &__container {
          display: flex;
          flex-direction: column;
          gap: 15px;
          padding: 15px 0 30px 0;
        }
      }
    }
    .confirmationButtonBox {
      padding-bottom: 0;
  display: flex;
  justify-content: center;
  margin: 20px;
    }
  }
}
