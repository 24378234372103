.ModalThankYou {
  width: 1290px;
  height: 739px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 50px 96px;
  z-index: 110;

  img {
    width: 105px;
    height: auto;
    margin: 181px auto 48px;
  }

  &__Title {
    font-size: 2rem;
    text-align: center;
    font-weight: 600;
  }

  &__Description {
    font-size: 1.5rem;
    text-align: center;
    padding-top: 30px;
    font-weight: 300;
  }
}

.CloseButtonBox {
  padding-top: 50px;
  display: flex;
  justify-content: center;

  &__button {
    border: 2px solid #f7b01c;
    padding: 16px 61px;
    color: #373737;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color 150ms ease-in-out;

    &:hover,
    &:focus {
      color: #fff;
      outline: 0;
    }

    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 100%;
      background: #f7b01c;
      z-index: -1;
      transition: width 150ms ease-in-out;
    }

    &:hover {
      color: #fff;
      &:after {
        width: 110%;
      }
    }
  }
}

@media (max-width: 1366px) and (min-width: 768px) {
  .ModalThankYou {
    width: 768px;
    height: 80%;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .ModalThankYou {
    width: 100%;
    height: 100%;
    padding: 20px 10px;

    & img  {
      margin: 10% auto;
      width: 80px;
      height: auto;
    }
  }
}
