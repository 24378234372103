.SelectionDataAndTime {
  max-width: 850px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 80px;
  gap: 70px;
  height: 41px;

  &__time {
    display: none;
    position: relative;
    border-bottom: 1px solid black;

    &--img {
      position: absolute;
      top: 7px;
      left: 8px;
    }
    &__customSelect {
      position: relative;
      display: flex;
      justify-content: flex-start;
      width: 390px;
      height: 41px;

      &__select {
        max-width: 850px;
        border: none;
        appearance: none;
        width: 100%;
        padding: 5px 0 0 50px;
        background-color: transparent;
        font-size: 1rem;

        &:focus {
          outline: none;
        }
      }
    }
  }
}

select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  position: fixed;
  bottom: 100%;
  right: 100%;
}

