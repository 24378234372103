.ModalError {
  width: 80.625rem;
  height: 52.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 110;
  background-color: #fff;
  line-height: 40px;
  overflow-y: auto;
  overflow-x: hidden;

  padding: 50px 96px;

  &__closeBtnContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    &__closeBtn {
      height: 24px;
      width: 24px;
      background-color: white;
      border-radius: 10px;
      position: relative;
      z-index: 2;
      position: relative;

      &::after,
      &::before {
        content: "";
        position: absolute;
        width: 20px;
        height: 1px;
        background-color: black;
        border-radius: 5px;
        top: 16px;
        box-shadow: 0 0 2px 0 black;
      }

      &::before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);
        left: 2px;
      }

      &::after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        transform: rotate(-45deg);
        right: 2px;
      }
      &:hover::before {
        background-color: #c80000;
      }

      &:hover::after {
        background-color: #c80000;
      }
    }
  }

  // {
  //   font-size: 30px;
  //   display: flex;
  //   justify-content: flex-end;
  //   cursor: pointer;
  // }

  &__icon {
    min-width: 80px;
    min-height: 80px;
    background-color: white;
    border-radius: 50%;
    border: 5px solid #f7b01c;
    position: relative;
    margin-bottom: 20px;

    &::before {
      position: absolute;
      top: 22px;
      left: 27px;
      content: "!";
      font-size: 60px;
      color: #f7b01c;
    }
  }

  &__content {
    width: 64.688rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__header {
      width: 45.938rem;
      height: 18.625rem;
      background-image: url(../../../public/img/plugin.svg);
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      margin-top: -1.563rem;

      &__title {
        font-size: 2rem;
        font-weight: 600;
        padding: 0.625rem 0 1.25rem;
        font-weight: 600;
      }
      &__description {
        font-size: 1.25rem;
        text-align: center;
        font-weight: 400;
        line-height: 1.8;
      }
    }

    &__body {
      &__step {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 6.875rem;
        position: relative;
      }
    }

    &__description {
      padding-bottom: 3.688rem;
      & p {
        text-align: left;
      }
    }
  }
}

@media (max-width: 1366px) and (min-width: 1110px) {
  .ModalError {
    width: 1110px;
    height: 90%;
    padding: 20px 40px;
    line-height: 2rem;
    overflow-y: auto;
  }
}

@media (max-width: 1110px) and (min-width: 768px) {
  .ModalError {
    width: 768px;
    height: 90%;
    padding: 20px 40px;

    &__content {
      max-width: 90%;

      &__header {
        width: auto;
        height: auto;
        margin-bottom: 30px;
        background-image: none;
      }

      &__body {
        &__step {
          display: flex;
          flex-direction: column;
          gap: 50px;
          position: relative;
        }
      }

      &__description {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .ModalError {
    width: 100%;
    height: 100%;
    padding: 20px 40px;

    &__content {
      max-width: 90%;

      &__header {
        // width: auto;
        // height: auto;
        margin-bottom: 30px;
        background-image: none;
      }

      &__body {
        &__step {
          display: flex;
          flex-direction: column;
          gap: 50px;
          position: relative;
        }
      }

      &__description {
        font-size: 1rem;
      }
    }
  }
}

@media (max-height: 1035px) and (max-width: 1110px) {
  .ModalError__content__header {
    margin-top: 275px;
  }
}

@media (max-height: 835px) and (max-width: 1110px) {
  .ModalError__content__header {
    margin-top: 330px;
  }
}

@media (max-height: 780px) and (max-width: 1110px) {
  .ModalError__content__header {
    margin-top: 475px;
  }
}

@media (max-height: 615px) and (max-width: 1110px) {
  .ModalError__content__header {
    margin-top: 635px;
  }
}

@media (max-height: 450px) and (max-width: 1110px) {
  .ModalError {
    width: 100%;
    height: 100%;

    &__content__header {
      margin-top: 700px;
    }
  }
}


@media (max-height: 1035px) and (max-width: 768px) {
  .ModalError__content__header {
    margin-top: 400px;
  }
}

@media (max-height: 835px) and (max-width: 768px) {
  .ModalError__content__header {
    margin-top: 430px;
  }
}

@media (max-height: 780px) and (max-width: 768px) {
  .ModalError__content__header {
    margin-top: 620px;
  }
}

@media (max-height: 615px) and (max-width: 768px) {
  .ModalError__content__header {
    margin-top: 800px;
  }
}

@media (max-height: 450px) and (max-width: 768px) {
  .ModalError {
    width: 100%;
    height: 100%;

    &__content__header {
      margin-top: 960px;
    }
  }
}